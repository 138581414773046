import IQuizResults from '../../interfaces/IQuizResults';
import QuizResult from '../../components/quiz/result/parenting-attachment/QuizResult';
import image from '../../assets/images/report/parenting-attachment.png';

const ATTACHMENT_STYLE_SECURE = 'secure';
const ATTACHMENT_STYLE_DISMISSING = 'dismissing';
const ATTACHMENT_STYLE_ANXIOUS = 'anxious';
const ATTACHMENT_STYLE_DISORGANIZED = 'disorganized';

const colorConfig: any = {
  [ATTACHMENT_STYLE_SECURE]: {
    color: '#055C5C',
    backgroundColor: '#0D7C7B',
  },
  [ATTACHMENT_STYLE_DISORGANIZED]: {
    color: '#AE1D32',
    backgroundColor: '#CE2638',
  },
  [ATTACHMENT_STYLE_DISMISSING]: {
    color: '#336EA7',
    backgroundColor: '#5099E1',
  },
  [ATTACHMENT_STYLE_ANXIOUS]: {
    color: '#CA6C0A',
    backgroundColor: '#F29C43',
  },
};
export class ParentingAttachment implements IQuizResults {
  getResultComponent() {
    return QuizResult;
  }
  

  getResultPageProps({ score, redirectUrl }: any) {
console.log(score);
    return {
      subtitle: 'Your dominant style is:',
      title: `${score.score_range}`,
      description: 'This result may indicate that you also have this attachment style, in general. However our attitudes towards parenting can differ from our attitudes towards other relationships, so you may not have the same general and parenting attachment styles.',
      bulletPoints: [
        'Complete breakdown of your score.',
        'More information about steps you can take.',
      ],
      color: colorConfig[score.style]?.color || '#7cd9dd',
      backgroundColor: colorConfig[score.style]?.backgroundColor || '#2da1a4',
      redirectUrl,
      image,
    };
  }
}