import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getComponentByKey } from '../../../libs/helpers/surveyIntroductionHelper';
import Question from '../../../interfaces/Question';
import QuestionPagination from '../../../interfaces/QuestionPagination';
import SurveyStat from '../../../interfaces/SurveyStat';
import UserAnswer from '../../../interfaces/UserAnswer';
import useIndexTracker from '../../../libs/hooks/useIndexTracker';

interface Props {
  isLoading: boolean;
  canStepBack: boolean;
  surveyStat: SurveyStat;
  questionPagination: QuestionPagination;
  userAnswers: UserAnswer[];
  setIsLoading: (value: boolean) => Function;
  getSurveyQuestions: (id: number, params?: object) => Promise<any>;
  completeStep: () => Promise<any>;
  skipStep: () => Promise<any>;
  startStep: () => Promise<any>;
  stepBack: () => Function;
  setNavbar: (value: any) => Function;
  setProgress: (value: number) => Function;
}

const useQuizSurvey = ({
  isLoading,
  canStepBack,
  surveyStat,
  questionPagination,
  userAnswers,
  getSurveyQuestions,
  setIsLoading,
  completeStep,
  startStep,
  skipStep,
  stepBack,
  setNavbar,
  setProgress,
}: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const trackerItems = questionPagination.data.map((q) => ({
    value: userAnswers.find((x) => x.question_id === q?.id)?.answer_id ?? null,
    required: true,
  }));

  const { index: questionIndex, setIndex: setQuestionIndex } = useIndexTracker({
    items: trackerItems,
    defaultLast: true,
  });

  const userAnsweredQuestionIds = new Set<number>();

  userAnswers.forEach((userAnswer) => {
    userAnsweredQuestionIds.add(userAnswer.question_id);
  });

  const IntroComponent = getComponentByKey(
    surveyStat.quiz_survey.settings['introduction'],
  );

  useEffect(() => {
    if (!isLoading) {
      scrollToWindowTop();
      updateNavbar();
      updateProgress();
    }
  }, [isLoading]);

  useEffect(() => {
    const { current_page: page, last_page } = questionPagination;

    if (last_page > 1) {
      updateSearchParams({ page });
    }
  }, [questionPagination.current_page]);

  useEffect(() => {
    if (!showIntroComponent()) {
      fetchSurveyQuestions({ page: searchParams.get('page') });
    }
  }, [surveyStat.quiz_survey]);

  useEffect(() => {
    if (hasIntro() && isStarted()) {
      fetchSurveyQuestions({ page: searchParams.get('page') });
    }
  }, [surveyStat.is_started]);

  useEffect(() => {
    const notStarted = !isStarted();
    const hasIntroPage = hasIntro();

    if (notStarted && !hasIntroPage) {
      start();
    }

    if (notStarted && hasIntroPage) {
      setIsLoading(false);
    }
  }, [surveyStat.id]);

  const fetchSurveyQuestions = (params = {}) => {
    setIsLoading(true);
    getSurveyQuestions(surveyStat.id, params)
      .catch(({ message }) => {
        toast.error(message);
      })
      .finally(() => {
        setQuestionIndex(-1);
        setHasErrors(false);
        setIsLoading(false);
      });
  };

  const submit = () => {
    if (validate()) {
      setHasErrors(false);
    } else {
      setHasErrors(true);
      return;
    }

    if (isLastPage()) {
      setIsSubmitting(true);
      completeStep()
        .then(() => setIsLoading(true))
        .catch((e) => toast.error(e.message))
        .finally(() => setIsSubmitting(false));
    } else {
      fetchSurveyQuestions({ page: questionPagination.current_page + 1 });
    }
  };

  const skip = () => {
    setIsLoading(true);
    return skipStep().catch(({ message }) => {
      setIsLoading(false);
      toast.error(message);
    });
  };

  const start = () => {
    setIsLoading(true);
    return startStep();
  };

  const canGoBack = () => {
    return canStepBack || questionPagination.current_page > 1;
  };

  const goBack = () => {
    const { current_page } = questionPagination;

    if (current_page > 1) {
      fetchSurveyQuestions({ page: current_page - 1 });
    } else {
      setIsLoading(true);
      stepBack();
    }
  };

  const validate = () => {
    return questionPagination.data.every(
      (question: Question, index: number) => {
        if (!validateQuestion(question)) {
          setQuestionIndex(index);
          return false;
        }
        return true;
      },
    );
  };

  const validateQuestion = (question: Question) =>
    userAnsweredQuestionIds.has(question.id);

  const isLastPage = () =>
    questionPagination.last_page === questionPagination.current_page;

  const canContinueLater = (): boolean => {
    const { current_page } = questionPagination;
    const settings = surveyStat.quiz_survey.settings;
    const clEnabled = settings['cl-enabled'];
    const clStep = settings['cl-step'];

    return clEnabled && current_page >= clStep;
  };

  const scrollToWindowTop = () => {
    const options: any = {
      top: 96,
      left: 0,
      behavior: 'instant',
    };

    window.scrollTo(options);
  };

  const updateSearchParams = (params = {}) => {
    const sParams = Array.from(searchParams.entries()).reduce(
      (obj: any, [key, val]: [any, any]) => ({ ...obj, [key]: val }),
      {},
    );

    const newSearchParams: any = {
      ...sParams,
      ...params,
    };

    setSearchParams(newSearchParams);
  };

  const updateNavbar = () => {
    const leftAction = canGoBack()
      ? {
          content: 'Back',
          onClick: goBack,
        }
      : { content: 'Cancel', onClick: () => navigate('/') };

    const rightAction = canSkip()
      ? {
          content: 'Skip',
          onClick: skip,
          props: { disabled: isLoading },
        }
      : null;

    setNavbar({ leftAction, rightAction });
  };

  const updateProgress = () => {
    const { current_page, last_page } = questionPagination;

    if (current_page && last_page) {
      setProgress(((current_page - 1) / last_page) * 100);
    }
  };

  const canSkip = () => isSkippable() || isPartnerSkippable();

  const isSkippable = (): boolean => {
    return surveyStat?.quiz_survey?.settings['is_skippable'] === 1;
  };

  const isPartnerSkippable = (): boolean => {
    return surveyStat?.is_partner_skippable === 1;
  };

  const isStarted = () => surveyStat.is_started === 1;

  const hasIntro = () => IntroComponent !== null;

  const showIntroComponent = () => !isStarted() && hasIntro();

  const getIntroProps = () => ({
    skippable: canSkip(),
    skip,
    start,
  });

  return {
    isLoading,
    isSubmitting,
    hasErrors,
    validateQuestion,
    surveyStat,
    questionPagination,
    submit,
    questionIndex,
    setQuestionIndex,
    canContinueLater,
    userAnswers,
    showIntroComponent,
    IntroComponent,
    getIntroProps,
  };
};

export default useQuizSurvey;
