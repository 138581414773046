import React, { useEffect, useState } from 'react';
import { ReactComponent as DotIcon } from '../../assets/icons/dot.svg';
import './RangeSlider.css';

interface IOption {
  label: string | TrustedHTML;
  value: any;
}

interface Props {
  name: string;
  options: IOption[];
  selectedOption: any;
  setSelectedOption: any;
  isActive?: boolean;
  placeholder?: string;
  step?: number;
  disabled?: boolean;
}

const RangeSlider: React.FC<Props> = ({
  name,
  options,
  selectedOption,
  setSelectedOption,
  placeholder = 'Slide the button',
  step = 0.1,
  disabled = false,
}) => {
  const sliderID = `range_slider_${name}`;
  const optionsCount = options.length;

  const [valueIndex, setValueIndex]: any = useState('');

  const getValue = () => {
    if (valueIndex >= 0) {
      return valueIndex;
    }

    return optionsCount % 2 === 0 ? 0 : (optionsCount - 1) / 2;
  };

  useEffect(() => {
    const newValueIdx =
      options.findIndex((o) => o?.value === selectedOption?.value) || 0;

    setValueIndex(newValueIdx);
  }, [selectedOption]);

  const onChangeHandler = (e: any) => {
    const newValueIdx = Math.round(e.target.value);
    setValueIndex(newValueIdx);
  };

  const onMouseUpHandler = (e: any) => {
    const newValue = options[e.target.value];

    if (newValue) {
      setSelectedOption(newValue);
    }
  };

  const getTickWrapperStyle = () => {
    const optionWidthPerc = 100 / optionsCount;
    const widthPerc = 100 + optionWidthPerc;

    return {
      width: `${widthPerc}%`,
      margin: `0 -${optionWidthPerc / 2}%`,
    };
  };

  const getTickStyle = () => {
    return {
      minWidth: `calc(100% / ${optionsCount})`,
    };
  };

  return (
    <div className={`range-slider-wrapper ${selectedOption ? 'active' : ''}`}>
      <label
        dangerouslySetInnerHTML={{
          __html: selectedOption?.label || placeholder,
        }}
      />
      <div className='range-slider'>
        <input
          id={sliderID}
          type='range'
          className='custom-range-slider'
          min={0}
          max={optionsCount - 1}
          step={step}
          onChange={onChangeHandler}
          onMouseUp={onMouseUpHandler}
          onTouchEnd={onMouseUpHandler}
          value={getValue()}
          disabled={disabled}
        />
        <div className='dot-wrapper'>
          {options.map((_x, index) => (
            <DotIcon key={`dot-${index}`} />
          ))}
        </div>
        <div className='tick-wrapper' style={getTickWrapperStyle()}>
          {options.map(({ label }, index) => (
            <div
              className='tick'
              key={`${label}-${index}`}
              dangerouslySetInnerHTML={{ __html: label }}
              style={getTickStyle()}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RangeSlider;
