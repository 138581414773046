import bgImage from '../../../../assets/images/report/background/bg-emotional-regulation.svg';
import SendReportCard from '../../send-report-card/SendReportCard';
import '../index.css';
import './QuizResult.css';

interface Props {
  subtitle: string;
  title: string;
  description: string;
  image?: string;
  bulletPoints?: string[];
  color?: string;
  titleFontSize?: string;
  onSuccessCallback: () => void;
}

const QuizResult: React.FC<Props> = ({
  subtitle,
  title,
  description,
  image,
  bulletPoints = [],
  onSuccessCallback,
}) => {
  return (
    <div
      className='er-quiz-wrapper'>
      <div
        className='quiz-results er-quiz-result'
        style={{
          backgroundImage: `url(${bgImage})`,
        }}
      >
        <div className='content flex-column flex-xl-row'>
          <div className='result-wrapper'>
            <span className='subtitle'>{subtitle}</span>
            <h1 className='title'>{title}</h1>
            <span className='description'>{description}</span>
          </div>
          <div className='send-report-wrapper mb-5 mb-lg-0'>
            <SendReportCard
              imageSrc={image}
              bulletPoints={bulletPoints}
              onSendReportSuccess={onSuccessCallback}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizResult;
