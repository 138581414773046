import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from '../../modal/Modal';
import env from '../../../env';
import './CoupleDisclaimer.css';

const CoupleInviteeDisclaimer = ({ isOpened, setIsOpened }: any) => {
  const [policyConsent, setPolicyConsent]: any = useState(false);
  const [recieveEmailsConsent, setRecieveEmailsConsent]: any = useState(false);

  const onContinue = () => {
    if (policyConsent && recieveEmailsConsent) {
      setIsOpened(false);
    }
  };

  useEffect(() => {
    setPolicyConsent(false);
    setRecieveEmailsConsent(false);
  }, [isOpened]);

  return (
    <Modal
      id='couple-disclaimer'
      isOpened={isOpened}
      setIsOpened={setIsOpened}
      data-bs-backdrop='static'
    >
      <Modal.Header showCloseButton={false}>
        <h5 className='mb-0'>Disclaimer</h5>
      </Modal.Header>
      <Modal.Body>
        <div className='mb-3'>
          By taking this quiz you confirm that you are above the age of legal
          consent in your country and eligible to participate in this quiz.
        </div>
      </Modal.Body>
      <Modal.Footer className='modal-footer pt-0 justify-content-between'>
        <div>
          <div className='form-check'>
            <input
              id='policy-agree'
              type='checkbox'
              className='form-check-input'
              value='on'
              onChange={(e: any) => setPolicyConsent(e.target.checked)}
              checked={policyConsent}
            />
            <label className='form-check-label' htmlFor='policy-agree'>
              {'I agree to '}
              <Link to={`${env.wordpressUrl}/privacy-policy`} target='_blank'>
                Privacy Policy
              </Link>
              {' & '}
              <Link to={`${env.wordpressUrl}/terms`} target='_blank'>
                Terms and Conditions
              </Link>
            </label>
          </div>
          <div className='form-check'>
            <input
              id='recieve-emails-agree'
              type='checkbox'
              className='form-check-input'
              value='on'
              onChange={(e: any) => setRecieveEmailsConsent(e.target.checked)}
              checked={recieveEmailsConsent}
            />
            <label className='form-check-label' htmlFor='recieve-emails-agree'>
              I agree to that The Attachment Project can send me emails
            </label>
          </div>
        </div>
        <Modal.ActionButton
          className='btn btn-secondary btn-agree'
          onClick={onContinue}
          disabled={!policyConsent || !recieveEmailsConsent}
        >
          Continue
        </Modal.ActionButton>
      </Modal.Footer>
    </Modal>
  );
};

export default CoupleInviteeDisclaimer;
