import IQuizResults from "../../interfaces/IQuizResults";
import QuizResult from "../../components/quiz/result/crq/QuizResult";
import image from "../../assets/images/report/crq.png";

const LOW = "Low";
const MEDIUM = "Medium";
const HIGH = "High";
const VERY_HIGH = "Very High";

export class CRQ implements IQuizResults {
  getResultComponent() {
    return QuizResult;
  }

  getResultPageProps({ score, redirectUrl }: any) {
    
    const descriptions: Record<string, string> = {
      [LOW]:
        "A low score suggests a high level of relational inflexibility and overall dissatisfaction. This may reflect frequent negative behaviors and perceptions from and for each other. You and your partner may struggle with insecure attachment patterns, possibly leading to significant challenges in maintaining healthy relationships.",
      [MEDIUM]:
        "A medium score suggests mixed experiences in this relationship, with a balance of flexible and inflexible aspects. This indicates some variability in attachment security, with both positive and negative relational experiences. This can happen in relationships where both – or at least one of the partners –has an insecure attachment style.",
      [HIGH]:
        "A high score indicates generally flexible and positive relational functioning. There are likely strong supportive behaviors and secure attachment patterns in one, or perhaps even both, partners. Both you and your partner typically enjoy fulfilling relationships, with occasional challenges. No relationship is perfect, but it seems that you are both working towards a healthy and well-functioning relationship!",
      [VERY_HIGH]:
        "A very high score suggests exceptionally flexible and positive relational functioning.Your relationship might be characterized by strong support, intimacy, and secure attachment. Both you and your partner are capable of experiencing highly satisfying and stable relationships, marked by mutual respect and understanding. Of course, no relationship is perfect and it certainly may not come easy, but you are both contributing to a healthy and well-functioning relationship!",
    };

    return {
      subtitle: "Your CRQ score is:",
      title: `${score.total.score}/100`,
      description: descriptions[score.total.score_range],
      bulletPoints: [
        "Complete breakdown of your score.",
        "More information about steps you can take.",
      ],
      color: "#7cd9dd",
      backgroundColor: "#2da1a4",
      redirectUrl,
      image,
    };
  }
}
